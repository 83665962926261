.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transform: 0.4s;
	border-radius: 34px;
}
.slider:hover {
	background-color: #adadad;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: rgb(201, 0, 0);
}

/* input:focus + .slider {
	box-shadow: 0 0 1px red;
} */

input:checked + .slider::before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
